<script lang="ts" setup>
    import { OffsetGroup } from '~/data/OffsetGroup'
    import { OffsetItem } from '~/data/OffsetItem'

    const { getEntryBySlug } = useStatamicEntries()
    const { data: page, refresh } = await useAsyncData(`entries/pages/${currentSlug()}`, () =>
        getEntryBySlug<Page>({ collection: 'pages', value: currentSlug() }),
    )

    if (import.meta.client) {
        const { $haveFeatureImage } = useNuxtApp()
        $haveFeatureImage.value = page.value?.feature_image != null
    }

    onMounted(() => {
        window.onmessage = (event) => {
            if (event.data.name === 'statamic.preview.updated') {
                refresh()
            }
        }
    })

    useHead({
        bodyAttrs: {
            class: { [`page-${currentSlug()}`]: true },
        },
        title: (() => {
            if (page.value?.title && page.value.title != 'Home') {
                return page.value.title
            }

            return null
        })(),
    })
</script>

<template>
    <div class="page block-gap-b" :class="`page-${currentSlug()}`">
        <template v-if="page">
            <PageHeader
                :image="page.feature_image"
                :content="page.page_intro ?? ''"
                :faded="currentSlug() != 'home'"
            >
                <h1 v-if="page.slug !== 'home'">{{ page.title }}</h1>
            </PageHeader>

            <RoomToText
                v-if="page.room_to_content"
                :content="page.room_to_content"
                :toOffset="page.room_to_to_offset"
            />

            <template v-for="block in page.blocks">
                <BlockTwoColumnText
                    v-if="block.type === 'text_two_columns'"
                    :block="block as TwoColumnTextBlock"
                />

                <BlockFeaturedProject
                    v-else-if="block.type === 'featured_project'"
                    :block="block as FeaturedProjectBlock"
                />

                <BlockProjectCallToAction
                    v-else-if="block.type === 'project_call_to_action'"
                    :block="block as ProjectCallToActionBlock"
                />

                <BlockAccordions
                    v-else-if="block.type === 'accordions'"
                    :block="block as AccordionsBlock"
                />

                <BlockLogos
                    v-else-if="block.type === 'logos'"
                    :block="block as LogosBlock"
                />

                <BlockLinks v-else-if="block.type === 'links'" :block="block as LinksBlock" />

                <div
                    v-else-if="block.type === 'call_to_action'"
                    class="layout-grid block-gap-y pt-8 sm:pt-12 lg:pt-32"
                >
                    <BlockCallToAction
                        class="max-w-[350px]"
                        :class="
                            new OffsetGroup(
                                new OffsetItem(3, 3),
                                new OffsetItem(2, 2),
                                new OffsetItem(0, 1),
                            ).classes
                        "
                        :block="block as CallToActionBlock"
                    />
                </div>
            </template>
        </template>
    </div>
</template>
